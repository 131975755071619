









































































































































































































































import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { Currency } from '@/app/core/dto/money.dto';
import FormMixin from '@/app/core/mixins/form.mixin';
import { email, gte, required } from '@/app/core/validation';
import DatePicker from '@/app/core/components/date-picker.vue';
import TimePicker from '@/app/core/components/time-picker.vue';
import MoneyInput from '@/app/core/components/money-input.vue';
import ChannelSelect from '@/app/core/components/channel-select.vue';
import HandlesErrorMixin from '@/app/core/mixins/handles-error.mixin';
import LanguageSelect from '@/app/core/components/language-select.vue';
import ApartmentSelect from '@/app/core/components/apartment-select.vue';
import ReservationDto from '../dto/reservation.dto';

@Component({
  components: {
    DatePicker,
    TimePicker,
    MoneyInput,
    ChannelSelect,
    ApartmentSelect,
    LanguageSelect,
  },
})
export default class BlockingToReservationForm extends Mixins(FormMixin, HandlesErrorMixin) {
  @Prop({ type: Object, required: true })
  reservation!: ReservationDto;

  private defaultData: Partial<ReservationDto> = {
    guest: {},
    payment: {
      price: {
        value: 0,
        currency: Currency.USD,
      },
      prepayment: {
        value: 0,
        currency: Currency.USD,
      },
      deposit: {
        value: 0,
        currency: Currency.USD,
      },
    },
  };

  protected data: Partial<ReservationDto> = this.defaultData;

  protected rules = {
    adults: [gte(0)],
    children: [gte(0)],
    guest: {
      firstname: [required],
      laststname: [required],
      email: [required, email],
    },
    payment: {
      price: [gte(0)],
      prepayment: [gte(0)],
      deposit: [gte(0)],
    },
  };

  @Watch('reservation', { immediate: true })
  setData() {
    this.data.notice = this.reservation?.notice;
  }
}
