













import { Component } from 'vue-property-decorator';
import { FormAction } from '@/app/core/types';
import HandlesErrorMixin from '@/app/core/mixins/handles-error.mixin';
import ReservationDto from '../dto/reservation.dto';
import BlockingToReservationForm from '../components/blocking-to-reservation-form.vue';
import { createFromBlocking, getReservation } from '../services/reservations.service';

@Component({
  metaInfo(this: ReservationFromBlocking) {
    return { title: this.$t('pageTitle.reservationFromBlocking').toString() };
  },
  components: {
    BlockingToReservationForm,
  },
})
export default class ReservationFromBlocking extends HandlesErrorMixin {
  private loading = false;
  private action = FormAction.Create;
  private reservationId: string | null = null;
  private reservation: ReservationDto | null = null;

  async createFromBlocking(dto: ReservationDto) {
    if (!this.reservationId) return;

    this.loading = true;
    try {
      const response = await createFromBlocking(this.reservationId, dto);
      const { id } = response.data;

      this.$router.push(`/reservations/details/${id}`);
      this.$notify.success(this.$t('success.reservationCreated').toString());
    } catch (error) {
      this.handleError(error);
    } finally {
      this.loading = false;
    }
  }

  async getReservation() {
    if (!this.reservationId) return;

    this.loading = true;
    try {
      const response = await getReservation(this.reservationId);
      this.reservation = response.data;

      if (this.reservation.channel?.name !== 'Blocked channel') {
        this.$router.push({ name: '404' });
      }
    } catch (error) {
      if (this.isNotFoundError(error)) {
        this.$router.push({ name: '404' });
      } else {
        this.handleError(error);
      }
    } finally {
      this.loading = false;
    }
  }

  created() {
    this.reservationId = this.$route.params.id;
    this.getReservation();
  }
}
